import { createAsyncThunk, createSlice, isAnyOf } from "@reduxjs/toolkit";
import { FieldValues } from "react-hook-form";
import { toast } from "react-toastify";
import agent from "../../app/api/agent";
import { User } from "../../app/models/user";
import { router } from "../../app/router/Routes";
import { setBasket } from "../basket/basketSlice";

interface AccountState {
    user: User | null
}

const initialState: AccountState = {
    user: null,
}

export const signInUser = createAsyncThunk<User, FieldValues>(
    'account/signInUser',
    async (data, thunkAPI) => {
        try {
            const userDto = await agent.Account.login(data)
            const { basket, ...user } = userDto
            if (basket) thunkAPI.dispatch(setBasket(basket))
            localStorage.setItem('user', JSON.stringify(user))
            return user
        }
        catch(error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)


export const signInUserWithGoogle = createAsyncThunk<User, FieldValues>(
    'account/signInUserWithGoogle',
    async (data, thunkAPI) => {
        const userDto = await agent.Account.google({token: data})
        try {
            const { basket, ...user } = userDto
            user.isGoogle = true
            if (basket) thunkAPI.dispatch(setBasket(basket))
            localStorage.setItem('user', JSON.stringify(user))
            return user
        }
        catch(error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)

export const createTestAdmin = createAsyncThunk<User, FieldValues>(
    'account/createTestAdmin',
    async (data, thunkAPI) => {
        const userDto = await agent.Account.createTestAdmin()
        try {
            const { basket, ...user } = userDto
            if (basket) thunkAPI.dispatch(setBasket(basket))
            localStorage.setItem('user', JSON.stringify(user))
            return user
        }
        catch(error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    }
)

export const fetchCurrentUser = createAsyncThunk<User>(
    'account/fetchCurrentUser',
    async (_, thunkAPI) => {
        thunkAPI.dispatch(setUser(JSON.parse(localStorage.getItem('user')!)))
        try {
            const userDto = await agent.Account.currentUser()
            const { basket, ...user } = userDto
            const userFromLocalStorage = JSON.parse(localStorage.getItem('user')!)
            user.isGoogle = userFromLocalStorage.isGoogle
            if (basket) thunkAPI.dispatch(setBasket(basket))
            localStorage.setItem('user', JSON.stringify(user))
            return user
        }
        catch(error: any) {
            return thunkAPI.rejectWithValue({error: error.data})
        }
    },
    {
        condition: () => {
            if(!localStorage.getItem('user')) return false
        }
    }
)

export const accountSlice = createSlice({
    name: 'account',
    initialState: initialState,
    reducers: {
        signOut: (state) => {
            state.user = null;
            localStorage.removeItem('user')
            router.navigate('/')
        },
        setUser: (state, action) => {
            let claims = JSON.parse(atob(action.payload.token.split('.')[1]))
            let roles = claims['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
            state.user = {...action.payload, roles: typeof(roles) === 'string' ? [roles] : roles}
        }
    },
    extraReducers: (builder => {
        builder.addCase(fetchCurrentUser.rejected, (state) => {
            state.user = null
            localStorage.removeItem('user')
            toast.error('Session expired - please login again')
            router.navigate('/')
        })
        builder.addMatcher(isAnyOf(signInUser.fulfilled, fetchCurrentUser.fulfilled, signInUserWithGoogle.fulfilled, createTestAdmin.fulfilled), (state, action) => {
            let claims = JSON.parse(atob(action.payload.token.split('.')[1]))
            let roles = claims['http://schemas.microsoft.com/ws/2008/06/identity/claims/role']
            state.user = {...action.payload, roles: typeof(roles) === 'string' ? [roles] : roles}
        })
        builder.addMatcher(isAnyOf(signInUser.rejected, signInUserWithGoogle.rejected, createTestAdmin.rejected), (state, action) => {
            throw action.payload
        })
    })
})

export const { signOut, setUser } = accountSlice.actions