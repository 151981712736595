import { Checkbox, FormControlLabel } from '@mui/material';
import * as React from 'react'
import { useController, UseControllerProps } from 'react-hook-form';

interface Props extends UseControllerProps {
    label: string
    disabled: boolean
}

export default function AppCheckBox(props: Props) {
    const {field} = useController({...props, defaultValue: false})

    return (
        <FormControlLabel 
            control={
                <Checkbox 
                {...field}
                checked={field.value}
                disabled={props.disabled}
                />
            }
            label={props.label}
        />
    )
}