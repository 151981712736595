import { Alert, AlertTitle, Button, ButtonGroup, List, ListItem, ListItemText, Typography } from "@mui/material";
import { Container } from "@mui/system";
import React, { useState } from 'react'
import agent from "../../app/api/agent";

export default function AboutPage() {
    const [validationErrors, setvalidationErrors] = useState<string[]>([])

    function getValidationError() {
        agent.TestErrors.getValidationerror()
        .catch(error => setvalidationErrors(error))
    }

    return (
        <Container>
        <Typography variant='h2' gutterBottom>
            Errors for testing purposes
        </Typography>
        <ButtonGroup fullWidth>
            <Button variant='contained' onClick={() => agent.TestErrors.get400Error()}>Test 400 Error</Button>
            <Button variant='contained' onClick={() => agent.TestErrors.get401Error()}>Test 401 Error</Button>
            <Button variant='contained' onClick={() => agent.TestErrors.get404Error()}>Test 404 Error</Button>
            <Button variant='contained' onClick={() => agent.TestErrors.get500Error()}>Test 500 Error</Button>
            <Button variant='contained' onClick={getValidationError}>Test Validation Error</Button>
        </ButtonGroup>
        {validationErrors.length > 0 && 
        <Alert severity='error'>
            <AlertTitle>Validation Errors</AlertTitle>
            <List>
                {validationErrors.map(error => (
                    <ListItem key={error}>
                        <ListItemText>{error}</ListItemText>
                    </ListItem>
                ))}
            </List>
        </Alert>
        }
        </Container>
    )
}